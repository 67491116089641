export default [
    
    {
        title: 'All products',
        url: '/shop/category-grid-3-columns-sidebar',
        
    },
    {
        title: 'Power Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools',
        submenu: {
            type: 'menu',
            menu: [
                
                { title: 'Drills', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Drills' },
                { title: 'Saws', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Saws' },
                { title: 'Woodworking', url: '/shop/category-grid-3-columns-sidebar?cat=Power Tools&subcat=Woodworking' }
            ],
        },
    },
    {
        title: 'Hand Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools',
        submenu: {
            type: 'menu',
            menu: [
                
                { title: 'Wrenches and Pliers', url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools&subcat=Wrenches and Pliers' },
                { title: 'Kits', url: '/shop/category-grid-3-columns-sidebar?cat=Hand Tools&subcat=Kits' }
            ],
        },
    },
    {
        title: 'Machine Tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools',
        submenu: {
            type: 'menu',
            menu: [
                
                { title: 'Chain saws', url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools&subcat=Chain saws' },
                { title: 'Large tools', url: '/shop/category-grid-3-columns-sidebar?cat=Machine Tools&subcat=Large tools' }
            ],
        },
    },
    {
        title: 'Other tools',
        url: '/shop/category-grid-3-columns-sidebar?cat=Other',
        submenu: {
            type: 'menu',
            menu: [
                
                { title: 'Compressors', url: '/shop/category-grid-3-columns-sidebar?cat=Other&subcat=Compressors' },
                { title: 'Plumbing', url: '/shop/category-grid-3-columns-sidebar?cat=Other&subcat=Plumbing' }
            ],
        },
    },
    {
        title: 'Makeup',
        url: '/shop/category-grid-3-columns-sidebar?cat=Makeup',
        submenu: {
            type: 'menu',
            menu: [
                
                { title: 'Products', url: '/shop/category-grid-3-columns-sidebar?cat=Makeup&subcat=Makeup Products' },
                { title: 'Accessories', url: '/shop/category-grid-3-columns-sidebar?cat=Makeup&subcat=Makeup accessories' }
            ],
        },
    }
    ,
    {
        title: 'Food',
        url: '/shop/category-grid-3-columns-sidebar?cat=Food',
        submenu: {
            type: 'menu',
            menu: [
                
                { title: 'Fruits', url: '/shop/category-grid-3-columns-sidebar?cat=Food&subcat=Fruits' },
                { title: 'Vegetables', url: '/shop/category-grid-3-columns-sidebar?cat=Food&subcat=Vegetables' }
            ],
        },
    }
    ,
    
    {
        title: 'Fashion',
        url: '/shop/category-grid-3-columns-sidebar?cat=Fashion',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'Clothes', url: '/shop/category-grid-3-columns-sidebar?cat=Fashion&subcat=Clothes' },
                { title: 'Accessories', url: '/shop/category-grid-3-columns-sidebar?cat=Fashion&subcat=Accessories' },
                { title: 'Shoes', url: '/shop/category-grid-3-columns-sidebar?cat=Fashion&subcat=Shoes' }
            ],
        },
    }
];
