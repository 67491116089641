import { useEffect } from "react";
import { useLocation } from "react-router-dom";

let previousPathname = null;
let previousSearch = null;

export default function RouteChangeMonitor() {
  const { pathname } = useLocation();
  const { search } = useLocation();

  useEffect(() => {
    if (previousPathname !== null || previousSearch !== null) {
        window.scrollTo(0, 0);
        window.dataLayer.push({
            event: 'route_change',
            pathname: pathname,
            previousPathname: previousPathname,
            search: search,
            previousSearch: previousSearch
        });
    }
    previousPathname = pathname;
    previousSearch = search;
  }, [pathname, search]);

  return null;
}
