// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';


function SitePageTypography() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Search Results Page', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Search Results Page — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="main-content" id="maincontent">
            </div>
        </React.Fragment>
    );
}

export default SitePageTypography;
